import Vue from 'vue';
import moment from 'moment-timezone';
import { MessageRecipient } from './messageRecipient';
import { NewMessageValidator } from './validators/newMessageValidator';
import { AttachmentModel } from '../../shared/models/attachment.model';
import { portalRoles } from '../../shared/enums/portalRoles';
import { Validatable } from './validators/validatable';
import { MessageBoxModel } from '../../shared/models/messageBox.model';
import { messageOwnerTypes } from '../../shared/enums/messageOwnerTypes';
import { MessageModel } from '../../shared/models/message.model';
import { SubscriptionModel } from '../../shared/models/subscription.model';
import { docTypes } from '../../shared/enums/docTypes';
import { SenderModel } from '../../shared/models/sender.model';
import { RecipientModel } from '../../shared/models/recipient.model';
import { MailOwnerModel } from '../../shared/models/mailOwner.model';
import { InstitutionModel } from '../../shared/models/institution.model';
import { CommonInboxModel } from '../../shared/models/commonInbox.model';
import { OtpInboxModel } from '../../shared/models/otpInbox.model';
import { MessageDraftModel } from '../../shared/models/messageDraft.model';

import { getDefaultInstitutionCode } from '../../shared/utils/institutionUtil';
import { EmailContactModel } from '../../shared/models/emailContact.model';
import { MessageFormActionEnum } from '../components/messages/enums/messageFormActionEnum';
import { ThreadInfoLightModel } from '../../shared/models/threadInfoLight.model';

export class NewMessage implements Validatable {
  private static readonly MESSAGE_RECIPIENTS_TO_MANY_LIMIT = 400;
  private static readonly MESSAGE_BCC_RECIPIENT_LIMIT = 100;
  private static readonly MESSAGE_RECIPIENTS_TO_MANY_CHUNK_SIZE = 100;
  private readonly _validator: NewMessageValidator;

  constructor() {
    this._validator = new NewMessageValidator(this);
    this._action = MessageFormActionEnum.CREATE;
    this._messageText = '';
    this._attachments = [];
    this._messageRecipients = [];
    this._messageBccRecipients = [];
    this._extraRecipients = [];
    this._hasTooManyRecipientsWarningAccepted = false;
    this._messageSubject = '';
    this._profileRole = null;
    this._selectedOtpInbox = null;
    this._selectedInbox = null;
    this._blockedUsers = [];
    this._institutionCode = null;
    this._sensitive = false;
    this._showSenderInput = false;
    this._sender = null;
    this._subscriptionId = null;
    this._messageId = null;
    this._existingAttachments = [];
    this._hasSecureDocuments = false;
    this._replyOrForwardMessage = null;
  }

  private _action: MessageFormActionEnum;
  private _relatedThreadId: number;
  private _messageText: string;
  private _attachments: AttachmentModel[];
  private _messageRecipients: MessageRecipient[];
  private _messageBccRecipients: MessageRecipient[];
  private _extraRecipients: MessageRecipient[];
  private _hasTooManyRecipientsWarningAccepted: boolean;
  private _messageSubject: string;
  private _profileRole: portalRoles;
  private _selectedOtpInbox: number;
  private _selectedInbox: MessageBoxModel;
  private _blockedUsers: { institutionProfile: any; otpInbox: { id: any } }[];
  private _institutionCode: string;
  private _sensitive: boolean;
  private _showSenderInput: boolean;
  private _sender: SenderModel;
  private _subscriptionId: number;
  private _messageId: string;
  private _existingAttachments: AttachmentModel[];
  private _hasSecureDocuments: boolean;
  private _replyOrForwardMessage: MessageModel;
  private _thread: { subject: string } & any;

  get attachments(): AttachmentModel[] {
    return this._attachments;
  }

  set attachments(value: AttachmentModel[]) {
    this._attachments = value;
  }

  get action(): MessageFormActionEnum {
    return this._action;
  }

  set action(value: MessageFormActionEnum) {
    this._action = value;
  }

  get relatedThreadId(): number {
    return this._relatedThreadId;
  }

  set relatedThreadId(value: number) {
    this._relatedThreadId = value;
  }

  get messageText(): string {
    return this._messageText;
  }

  set messageText(value: string) {
    this._messageText = value;
  }

  get messageRecipients(): MessageRecipient[] {
    return this._messageRecipients.map(recipient => new MessageRecipient(recipient));
  }

  set messageRecipients(value: MessageRecipient[]) {
    this._messageRecipients = value;
  }

  get messageBccRecipients(): MessageRecipient[] {
    return this._messageBccRecipients.map(recipient => new MessageRecipient(recipient));
  }

  set messageBccRecipients(value: MessageRecipient[]) {
    this._messageBccRecipients = value;
  }

  get extraRecipients(): MessageRecipient[] {
    return this._extraRecipients.map(recipient => new MessageRecipient(recipient));
  }

  set extraRecipients(value: MessageRecipient[]) {
    this._extraRecipients = value;
  }

  get hasTooManyRecipientsWarningAccepted(): boolean {
    return this._hasTooManyRecipientsWarningAccepted;
  }

  set hasTooManyRecipientsWarningAccepted(value: boolean) {
    this._hasTooManyRecipientsWarningAccepted = value;
  }

  get messageSubject(): string {
    return this._messageSubject;
  }

  set messageSubject(value: string) {
    this._messageSubject = value;
  }

  get profileRole(): portalRoles {
    return this._profileRole;
  }

  set profileRole(value: portalRoles) {
    this._profileRole = value;
  }

  get selectedOtpInbox(): number {
    return this._selectedOtpInbox;
  }

  set selectedOtpInbox(value: number) {
    this._selectedOtpInbox = value;
  }

  get selectedInbox(): MessageBoxModel {
    return this._selectedInbox;
  }

  set selectedInbox(value: MessageBoxModel) {
    this._selectedInbox = value;
  }

  get blockedUsers(): { institutionProfile: any; otpInbox: any }[] {
    return this._blockedUsers;
  }

  set blockedUsers(value: { institutionProfile: any; otpInbox: any }[]) {
    this._blockedUsers = value;
  }

  get institutionCode(): string {
    return this._institutionCode;
  }

  set institutionCode(value: string) {
    this._institutionCode = value;
  }

  get isSensitive(): boolean {
    return this._sensitive;
  }

  set isSensitive(value: boolean) {
    this._sensitive = value;
  }

  get showSenderInput(): boolean {
    return this._showSenderInput;
  }

  set showSenderInput(value: boolean) {
    this._showSenderInput = value;
  }

  get sender(): SenderModel {
    return this._sender;
  }

  set sender(value: SenderModel) {
    this._sender = value;
  }

  get subscriptionId(): number {
    return this._subscriptionId;
  }

  set subscriptionId(value: number) {
    this._subscriptionId = value;
  }

  get messageId(): string {
    return this._messageId;
  }

  set messageId(value: string) {
    this._messageId = value;
  }

  get existingAttachments(): AttachmentModel[] {
    return this._existingAttachments;
  }

  get hasSecureDocuments(): boolean {
    return this._hasSecureDocuments;
  }

  set hasSecureDocuments(value: boolean) {
    this._hasSecureDocuments = value;
  }

  get replyOrForwardMessage(): MessageModel {
    return this._replyOrForwardMessage;
  }

  set replyOrForwardMessage(value: MessageModel) {
    this._replyOrForwardMessage = value;
  }

  get thread(): any {
    return this._thread;
  }

  set thread(value: any) {
    this._thread = value;
  }

  public get validator(): NewMessageValidator {
    return this._validator;
  }

  /**
   * validated model
   * @return  {boolean} true - if valid
   */
  public isValid(): boolean {
    return this._validator.validate();
  }

  /**
   * sets messageRecipients to empty array
   */
  public clearMessageRecipients(): void {
    this._messageRecipients = [];
  }

  /**
   * sets messageRecipients to empty array
   */
  public clearBccMessageRecipients(): void {
    this._messageBccRecipients = [];
  }

  public clearExtraMessageRecipients(): void {
    this._extraRecipients = [];
  }

  public get messageRecipientChunkSize(): number {
    return NewMessage.MESSAGE_RECIPIENTS_TO_MANY_CHUNK_SIZE;
  }

  public get messageRecipientsNumberLimit(): number {
    return NewMessage.MESSAGE_RECIPIENTS_TO_MANY_LIMIT;
  }

  public get isMessageRecipientsNumberAboveLimit(): boolean {
    return this._messageRecipients.length + this._extraRecipients.length > NewMessage.MESSAGE_RECIPIENTS_TO_MANY_LIMIT;
  }

  public get isExceedingBccRecipientLimit(): boolean {
    return this._messageBccRecipients.length > NewMessage.MESSAGE_BCC_RECIPIENT_LIMIT;
  }

  public get uniqueBccRecipients() {
    return this.getUniqueRecipients(this._messageBccRecipients);
  }

  public get uniqueRecipients() {
    return this.getUniqueRecipients(this._messageRecipients);
  }

  /**
   * gets information if message text has some sensitive content
   * @return  {boolean} true - if  messageText contains some sensitive information
   */
  public get isMessageContentSensitive(): boolean {
    return (
      this._messageText &&
      (this._messageText.includes('/dokumenter/sikre/external/') ||
        this._messageText.includes('/dokumenter/sikre/internal/'))
    );
  }
  /**
   * @description fills in  fields from chosenMessage and chosenSubscription to this (forwarded) message
   * @param  {MessageModel} chosenMessage - forwarded message
   * @param  {SubscriptionModel} chosenSubscription
   * @param  {string} messageSubject - subject of  forwarded message
   * @param  {boolean} isSensitive - isSensitive of forwarded message
   */
  public populateForwardMessageFields(
    chosenMessage: MessageModel,
    chosenSubscription: SubscriptionModel,
    messageSubject: string,
    isSensitive: boolean
  ): void {
    if (!chosenSubscription || !chosenMessage) {
      return;
    }

    chosenMessage.thread = { subject: chosenSubscription.subject };
    chosenMessage.sender.mailBoxOwnerType = chosenMessage.sender.mailBoxOwner.mailBoxOwnerType;
    chosenMessage.sender.id = chosenMessage.sender.mailBoxOwner.id;

    if (chosenSubscription.mailBoxOwner.mailBoxOwnerType === messageOwnerTypes.OTP_INBOX) {
      this._selectedOtpInbox = chosenSubscription.mailBoxOwner.id;
    }
    this._institutionCode = chosenSubscription.institutionCode;
    this._messageRecipients = [MessageRecipient.from(chosenMessage.sender)];
    this._messageSubject = messageSubject;
    this._messageText = '';
    this._sensitive = isSensitive;
    this._replyOrForwardMessage = chosenMessage;
    this._thread = { subject: chosenSubscription.subject };
  }

  /**
   * @description populate message form initial data
   * @param {ThreadInfoLightModel} threadInfoLight - basic information of the related thread when starting a new thread
   */
  public populateMessageFormData(threadInfoLight: ThreadInfoLightModel): void {
    if (!this._messageSubject) {
      const prepend = 'Vs. ';
      this._messageSubject = prepend + threadInfoLight.subject;
    }

    if (this._action === MessageFormActionEnum.ANSWER_THREAD_CREATOR) {
      this._replyOrForwardMessage = threadInfoLight.firstMessage;
    }
  }

  /**
   * @description gets unique copy of recipients from the given recipients list,
   * if recipient profile is  Profile - makes sure that it is the only one:
   * if it has OtpInboxId -> checks uniqness by otpInboxId
   * if it is not OtpInboxId -> checks uniqness by profileId
   * @param  {MessageRecipient[]} recipients - list of recipients
   * @return  {MessageRecipient[]} - list of unique recipients
   */
  public getUniqueRecipients(recipients: MessageRecipient[]): MessageRecipient[] {
    return recipients.reduce(
      (unique, recipient) =>
        recipient.type !== docTypes.PROFILE.toLowerCase() ||
        (!!recipient.otpInboxId && !unique.find(item => item.otpInboxId === recipient.otpInboxId)) ||
        (!recipient.otpInboxId && !unique.find(item => item.profileId === recipient.profileId))
          ? [...unique, new MessageRecipient(recipient)]
          : unique,
      []
    );
  }

  /**
   * @description gets creator of the message based on its data, profile and institutionsThatCanBeSeen - which will be set for request of blocking users
   * @param  {number | string} profileId - user profileId
   * @param  {InstitutionModel[]} institutionsThatCanBeSeen - list of institutionsThatCanBeSeen by the user
   * @return  {string | number | SenderModel} - message creator
   */
  getCreatorForBlockedUsersRequest(
    profileId: number | string,
    institutionsThatCanBeSeen: InstitutionModel[]
  ): string | number | SenderModel {
    // todo: REVIEW creator for blocked users request is differnt than for post Message, is this OK ?
    if (this._institutionCode) {
      return institutionsThatCanBeSeen.find(i => i.institutionCode === this._institutionCode).institutionProfileId;
    }

    return this._showSenderInput ? this._sender : profileId;
  }

  /**
   * @description gets creator of the message based on its data, profile and institutionsThatCanBeSeen, which will be set for posted message
   * @param  {InstitutionModel[]} institutionsThatCanBeSeen - list of institutionsThatCanBeSeen by the user
   * @return  {string | number | SenderModel| null} - message creator or null if it cannot be determined
   */
  getCreatorForPostMessage(institutionsThatCanBeSeen: InstitutionModel[]): string | number | SenderModel {
    if (this._selectedInbox && this._selectedInbox.mailBoxOwnerType === messageOwnerTypes.COMMON_INBOX) {
      return this._selectedInbox.value;
    }

    if (this._selectedOtpInbox) {
      return this._selectedOtpInbox;
    }

    if (this._institutionCode) {
      return institutionsThatCanBeSeen.find(i => i.institutionCode === this._institutionCode).institutionProfileId;
    }

    if (this._showSenderInput) {
      return this._sender;
    }
    return null;
  }

  /**
   * @description gets mailBoxOwner which will be set for posted message
   * @return  {messageOwnerTypes.COMMON_INBOX | messageOwnerTypes.OTP_INBOX | undefined} - mailBoxOwner
   */
  public getMailBoxOwnerTypeForPostMessage(): messageOwnerTypes | undefined {
    if (this._selectedInbox && this._selectedInbox.mailBoxOwnerType === messageOwnerTypes.COMMON_INBOX) {
      return messageOwnerTypes.COMMON_INBOX;
    }
    if (this._selectedOtpInbox) {
      return messageOwnerTypes.OTP_INBOX;
    }
    return undefined;
  }

  /**
   * Common inbox recipients are not blocked in communication channel, we can remove them from list of messageRecipients before checking the the blocking
   * @param  {MailOwnerModel} chosenFolderAndMailOwner
   * @return  {RecipientModel[]} - list of message recipients which don't have COMMON_INBOX mailbox
   */
  public getRecipientsWithoutCommonInbox(chosenFolderAndMailOwner: MailOwnerModel): RecipientModel[] {
    const messageRecipients = this.enrichRecipients(this.uniqueRecipients, chosenFolderAndMailOwner);
    const messageBccRecipients = this.enrichRecipients(this.uniqueBccRecipients, chosenFolderAndMailOwner);
    let allRecipients = [...messageRecipients, ...messageBccRecipients];

    allRecipients = allRecipients.filter(
      recipient =>
        recipient.mailBoxOwnerType === messageOwnerTypes.INSTITUTION_PROFILE ||
        recipient.mailBoxOwnerType === messageOwnerTypes.OTP_INBOX
    );

    // return unique recipients
    return allRecipients.filter((item, index, self) => index === self.findIndex(t => t.id === item.id));
  }

  /**
   * sets message institutionCode based on current selected inbox for mailBoxOwnerType: INSTITUTION_PROFILE or COMMON_INBOX
   * @param  {CommonInboxModel[]} commonInboxesThatCanBeSeen  common inboxes that can be seen by user
   * @param {InstitutionModel[]} institutionsThatCanBeSeen institutions that can be seen by user
   */
  public setInstitutionCodeFromSelectedInbox(
    commonInboxesThatCanBeSeen: CommonInboxModel[],
    institutionsThatCanBeSeen: InstitutionModel[]
  ): void {
    if (!this._selectedInbox) {
      return;
    }

    if (this._selectedInbox.mailBoxOwnerType === messageOwnerTypes.INSTITUTION_PROFILE) {
      this._institutionCode = String(this._selectedInbox.value);
      return;
    }

    if (this._selectedInbox.mailBoxOwnerType === messageOwnerTypes.COMMON_INBOX) {
      const commonInbox = commonInboxesThatCanBeSeen.find(commonInbox => commonInbox.id === this._selectedInbox.value);
      if (commonInbox) {
        this._institutionCode =
          institutionsThatCanBeSeen.find(
            institution =>
              commonInbox.participants &&
              institution.institutionProfileId === commonInbox.participants[0]?.mailBoxOwner.id
          )?.institutionCode || commonInbox.institutionCode;
      }
    }
  }

  /**
   *  sets messageRecipients - to only not blocked users
   *  @return returns only not blocked users from messagRecipients and messageBccRecipient
   */
  public excludeBlockedUsers(): MessageRecipient[] {
    return [
      ...this._messageRecipients.filter(
        profile =>
          !this._blockedUsers.some(user =>
            user.otpInbox ? profile.otpInboxId === user.otpInbox.id : profile.id === user.institutionProfile.id
          )
      ),
      ...this._messageBccRecipients.filter(
        profile =>
          !this._blockedUsers.some(user =>
            user.otpInbox ? profile.otpInboxId === user.otpInbox.id : profile.id === user.institutionProfile.id
          )
      ),
    ];
  }

  /**
   *  sets messageRecipients and messagebccRecipients  - to only not blocked users
   */
  public removeBlockedRecipients(): void {
    this._messageRecipients = this._messageRecipients.filter(
      profile =>
        !this._blockedUsers.some(user =>
          user.otpInbox ? profile.otpInboxId === user.otpInbox.id : profile.id === user.institutionProfile.id
        )
    );

    this._messageBccRecipients = this._messageBccRecipients.filter(
      profile =>
        !this._blockedUsers.some(user =>
          user.otpInbox ? profile.otpInboxId === user.otpInbox.id : profile.id === user.institutionProfile.id
        )
    );
  }

  public get anyRecipients() {
    return this._messageRecipients.length > 0 || this._messageBccRecipients.length > 0;
  }

  public get allRecipients() {
    return [...this._messageRecipients, ...this._messageBccRecipients];
  }

  /**
   * @description  gets given message recipients belonging to group users.
   * @param  {RecipientModel[]} recipients
   * @returns {MessageRecipient[]} intersection of recipients and users beeing members of the group
   */
  private getMembersFromGroup(recipients: RecipientModel[]): MessageRecipient[] {
    const membersFromGroup = recipients.filter(rec => rec.fromGroup);

    return membersFromGroup
      .filter(member =>
        recipients.some(
          recipient =>
            recipient.type === 'group' &&
            recipient.id == member.groupId &&
            (recipient.portalRole == null || recipient?.portalRole === member.portalRole)
        )
      )
      .map(recipient => new MessageRecipient(recipient));
  }

  /**
   * @description  iterates through unique recipients of the message and returns  them in chunks  or all at once (depends on hasTooManyRecipientsWarningAccepted flag)
   * @returns {recipients: MessageRecipient[], bccRecipients: MessageRecipient[]}  chunk of unique message recipients and bccRecipients  or all at once
   */
  public *getRecipientsIterator(): IterableIterator<{
    recipients: MessageRecipient[];
    bccRecipients: MessageRecipient[];
  }> {
    const uniqueRecipients = this.uniqueRecipients;

    yield { recipients: uniqueRecipients, bccRecipients: this.uniqueBccRecipients };
  }

  /**
   *  Gets search institution code for the selected inbox: INSTITUTION_PROFILE | COMMON_INBOX | CROSS_INSTITUTIONAL or chosenFolderAndMailOwner === OTP_INBOX
   *  @param  {CommonInboxModel[]} commonInboxes
   *  @param  {OtpInboxModel[]} otpInboxes
   *  @param  {InstitutionModel[]} institutions
   *  @returns {string[]}  institution code
   */
  public getInboxInstitutionCodes(
    commonInboxes: CommonInboxModel[],
    otpInboxes: OtpInboxModel[],
    institutions: InstitutionModel[]
  ): string[] {
    if (this._selectedInbox?.mailBoxOwnerType === messageOwnerTypes.INSTITUTION_PROFILE) {
      return [String(this._selectedInbox.value)];
    } else if (this._selectedInbox?.mailBoxOwnerType === messageOwnerTypes.COMMON_INBOX) {
      const commonInbox = commonInboxes.find(item => item.id === this._selectedInbox.value);
      if (commonInbox.participants == null) {
        return [commonInbox.institutionCode];
      }
      const mailBoxOwnerIds = commonInbox.participants.map(participant => participant.mailBoxOwner.id);
      return institutions
        .filter(institution => mailBoxOwnerIds.includes(institution.institutionProfileId))
        .map(institution => institution.institutionCode);
    } else {
      const otpInbox = otpInboxes.find(item => item.id === this._selectedOtpInbox);
      return otpInbox ? [otpInbox.regardingChild.institutionCode] : [];
    }
  }

  /**
   * Updates message recipients from given users
   * @param  {RecipientModel[]} users
   */
  public updateRecipients(users: RecipientModel[]): void {
    if (users.length === 0) {
      this.clearMessageRecipients();
    }

    // Makes sure to keep existing members from group since they are not part of invitees array
    this._messageRecipients = this.getMembersFromGroup(this._messageRecipients);
    const addedRecipients = this.getNewRecipients(users, this._messageRecipients);

    this._messageRecipients = [...this._messageRecipients, ...addedRecipients];
  }

  /**
   * Updates Bcc message recipients from given users
   * @param  {RecipientModel[]} users
   */
  public updateBccRecipients(users: RecipientModel[]): void {
    if (users.length === 0) {
      this.clearBccMessageRecipients();
    }

    // Makes sure to keep existing members from group since they are not part of invitees array
    this._messageBccRecipients = this.getMembersFromGroup(this._messageBccRecipients);
    const addedRecipients = this.getNewRecipients(users, this._messageBccRecipients);
    this._messageBccRecipients = [...this._messageBccRecipients, ...addedRecipients];
  }

  public updateExtraRecipients(users: RecipientModel[]): void {
    if (users.length === 0) {
      this.clearExtraMessageRecipients();
    }

    // Makes sure to keep existing members from group since they are not part of invitees array
    this._extraRecipients = this.getMembersFromGroup(this._extraRecipients);
    const addedRecipients = this.getNewRecipients(users, this._extraRecipients);

    this._extraRecipients = [...this._extraRecipients, ...addedRecipients];
  }

  /**
   * gets new  recipients from given users
   * @param  {RecipientModel[]} users - all selected users
   * @param {MessageRecipient[]} existingUsers - usres which already exist and will be not added
   * @return {MessageRecipient[]} list of new recipients created based on users and existing users
   */
  private getNewRecipients(users: RecipientModel[], existingUsers: MessageRecipient[]): MessageRecipient[] {
    let messageRecipients = [];

    for (const recipient of users) {
      const alreadyAddedRecipient = existingUsers.find(other => recipient.value === other.value) !== undefined;
      if (alreadyAddedRecipient) {
        continue;
      }
      const messageRecipient = this.getNewInvitee(recipient);
      messageRecipients = messageRecipient ? [...messageRecipients, messageRecipient] : messageRecipients;
    }
    return messageRecipients;
  }

  /**
   * creates new Recipient from the given recipient, sets mailboxOwnerrType for recipient
   * @param  {RecipientModel} recipient recipient which we consider to be added
   * @return {MessageRecipient | null} recipient to be added to message recipients or null - if it shouldnt be added
   */
  private getNewInvitee(recipient: RecipientModel): MessageRecipient | null {
    const messageRecipient = new MessageRecipient(recipient);

    if (messageRecipient.hasProfileValue || messageRecipient.hasGroupValue || messageRecipient.hasCommonBoxValue) {
      // group is represented as  members of the group, so we don't keep group
      if (messageRecipient.type === 'group') {
        return null;
      }

      messageRecipient.setMailBoxOwnerType();
      return messageRecipient;
    }
    if (messageRecipient.hasGuardianRole) {
      return messageRecipient;
    }
  }
  /**
   * formats recipients to be sent, fills in needed Otp id and mailBoxOwnerType (for OTP recipients)
   * @param  {RecipientModel[]} messageRecipients to be prepared
   * @param  {MailOwnerModel} chosenFolderAndMailOwner  - chosenFolderAndMailOwner
   * @return {RecipientModel[] }  prepared recipients - only id and mailBoxOwnerType returned
   */
  public enrichRecipients(
    messageRecipients: MessageRecipient[],
    chosenFolderAndMailOwner: MailOwnerModel
  ): RecipientModel[] {
    return messageRecipients.map(recipient => {
      recipient.setRecipientIdWhenOtpMailBox(chosenFolderAndMailOwner, this._selectedOtpInbox);

      return {
        ...recipient,
        id: recipient.id || recipient.mailBoxOwner.id,
        mailBoxOwnerType: recipient.mailBoxOwnerType || recipient.mailBoxOwner.mailBoxOwnerType,
      };
    });
  }

  /**
   * checks if selected message inbox differs from given chosenFolderAndMailOwner
   * @param {MailOwnerModel} chosenFolderAndMailOwner
   * @return {boolean }  true - if message selected inbox is different than chosenFolderAndMailOwner
   */
  public selectedInboxDifferentFromChosenFolder(chosenFolderAndMailOwner: MailOwnerModel): boolean {
    return (
      this._selectedInbox.mailBoxOwnerType !== chosenFolderAndMailOwner.mailOwnerType ||
      (this._selectedInbox.mailBoxOwnerType === messageOwnerTypes.COMMON_INBOX &&
        this._selectedInbox.value !== chosenFolderAndMailOwner.mailOwnerId)
    );
  }

  /**
   * sets given attachments to message, sets if message has secure documents flag
   * @param  {AttachmentModel[]}attachments  - attachemnts to be set for message
   */
  public setAttachments(attachments: AttachmentModel[]) {
    this._attachments = attachments;
    this._hasSecureDocuments = attachments.some(att => att.file && att.file.attachedSecureDocumentId);
  }

  /**
   * sets selectedInbox to INSTITUTION_PROFILE and its value to this.institutionCode -
   * only if not  already set  or selectedInbox mailBoxOwnerType equals Institution profile
   */
  public setSelectedInboxForInstitutionProfile(): void {
    if (this.selectedInbox && this.selectedInbox.mailBoxOwnerType !== messageOwnerTypes.INSTITUTION_PROFILE) {
      return;
    }
    if (this.institutionCode && this.profileRole !== portalRoles.OTP) {
      this.selectedInbox = {
        mailBoxOwnerType: messageOwnerTypes.INSTITUTION_PROFILE,
        value: this._institutionCode,
      };
    }
  }

  /**
   * returns  message updated from draft message
   * @param  {MessageDraftModel} draft
   * @param  {string} messageId
   * @param  {number} subscriptionId
   */
  public updateFromDraft(draft: MessageDraftModel, messageId: string, subscriptionId: number): void {
    // we remove information that users are from group - if restored from draft they will be as non group users inserted
    draft.messageRecipients.forEach(recipient => {
      delete recipient.fromGroup;
    });
    this._selectedOtpInbox = draft.selectedOtpInbox;
    this._sender = draft.selectedSender;
    this._attachments = draft.attachments;
    this._existingAttachments = draft.attachments;
    this._messageSubject = draft.thread ? draft.thread.subject : '';
    this._messageText = draft.latestMessage ? draft.latestMessage.text : '';
    this._messageRecipients = draft.recipients
      ? draft.recipients.map(recipient => new MessageRecipient(recipient))
      : [];
    this._messageBccRecipients = draft.bccRecipients
      ? draft.bccRecipients.map(recipient => new MessageRecipient(recipient))
      : [];
    this._extraRecipients = draft.extraRecipients
      ? draft.extraRecipients.map(recipient => new MessageRecipient(recipient))
      : [];
    this._institutionCode = draft.institutionCode;
    this._sensitive = draft.sensitive;
    this._messageId = messageId || draft.messageId;
    this._subscriptionId = subscriptionId || draft.subscriptionId;

    if (draft.action === MessageFormActionEnum.ANSWER_DIRECTLY) {
      this._messageRecipients = draft.messageRecipients;
      this._replyOrForwardMessage = draft.replyOrForwardMessage;
    }
    if (draft.selectedCommonInbox) {
      this._selectedInbox = {
        mailBoxOwnerType: messageOwnerTypes.COMMON_INBOX,
        value: Number(draft.selectedCommonInbox),
      };
    }
  }

  /**
   * gets message draft thread data
   * @param  {string} selectedDraftId
   * @param  {string} institutionCode
   * @param  {string} oldDraftThreadId
   *  @return {MessageDraftModel} record with tread data
   */
  getDraftThreadData(selectedDraftId: string, oldDraftThreadId: string, institutionCode: string): MessageDraftModel {
    const availableRecipients = this.excludeBlockedUsers();
    const draftThreadId = NewMessage.getDraftThreadId(selectedDraftId, oldDraftThreadId);

    return <MessageDraftModel>{
      action: this._action,
      relatedThreadId: this._relatedThreadId,
      thread: {
        subject: this._messageSubject,
        subscriptions: availableRecipients.map(function (person) {
          let fullName = person.label;
          if (!fullName) {
            fullName = Vue.filter('displayProfileNameWithMetadata')(person);
          }

          return {
            receiver: {
              fullName,
              address: person.aulaEmail,
              id: person.id,
              profileId: person.profileId,
            },
          };
        }),
      },
      selectedSender: this.sender,
      selectedOtpInbox: this.selectedOtpInbox,
      selectedCommonInbox:
        this.selectedInbox && this.selectedInbox.mailBoxOwnerType === messageOwnerTypes.COMMON_INBOX
          ? String(this.selectedInbox.value)
          : null,
      mailBoxOwner: {
        id: this.selectedOtpInbox,
        mailBoxOwnerType: messageOwnerTypes.OTP_INBOX,
      },
      institutionCode: institutionCode,
      attachments: this._attachments,
      recipients: this._subscriptionId && this._messageId ? [] : this._messageRecipients, // forwarded messages dont need recipients
      sensitive: this._sensitive,
      subscriptionId: this._subscriptionId,
      messageId: this._messageId,
      latestMessage: {
        text: this._messageText,
        sendDateTime: moment().format(),
      },
      text: '',
      id: draftThreadId,
      messageRecipients: this._messageRecipients,
      bccRecipients: this._messageBccRecipients,
      extraRecipients: this._extraRecipients,
      replyOrForwardMessage: this._replyOrForwardMessage,
      draft: true,
    };
  }

  /**
   * initializes selectedInbox and institutionCode for new message.
   * It returns selected mailOwnerType
   * @param  {MailOwnerModel} chosenFolderAndMailOwner
   * @param  {InstitutionModel[]} institutionsThatCanBeSeen
   * @param  {string[]} activeInstitutionCodes
   * @param  {OtpInboxModel[]} otpInboxes
   * @param  {CommonInboxModel[]} commonInboxesThatCanBeSeen
   * @return {messageOwnerTypes}  chosenFolderAndMailOwner mailOwnerType
   */
  public initializeSelectedInboxAndInstitutionCode(
    chosenFolderAndMailOwner: MailOwnerModel,
    institutionsThatCanBeSeen: InstitutionModel[],
    activeInstitutionCodes: string[],
    commonInboxesThatCanBeSeen: CommonInboxModel[]
  ): messageOwnerTypes {
    const mailOwnerType = chosenFolderAndMailOwner.mailOwnerType;

    if (this.profileRole === portalRoles.OTP) {
      this._selectedInbox = {
        mailBoxOwnerType: messageOwnerTypes.OTP_INBOX,
        value: this._selectedOtpInbox,
      };
      return mailOwnerType;
    }
    if (institutionsThatCanBeSeen.length === 0) {
      return mailOwnerType;
    }

    if (chosenFolderAndMailOwner.mailOwnerType === messageOwnerTypes.COMMON_INBOX) {
      this._selectedInbox = {
        mailBoxOwnerType: messageOwnerTypes.COMMON_INBOX,
        value: Number(chosenFolderAndMailOwner.mailOwnerId),
      };
      this.setInstitutionCodeFromSelectedInbox(commonInboxesThatCanBeSeen, institutionsThatCanBeSeen);
      return mailOwnerType;
    }

    if (
      institutionsThatCanBeSeen.some(
        institution => institution.institutionCode === this._messageRecipients[0]?.institutionCode
      )
    ) {
      this.institutionCode = this._messageRecipients[0].institutionCode;
    }
    if (this.institutionCode == null && institutionsThatCanBeSeen.find(inst => !inst.communicationBlock) != null) {
      this.institutionCode = getDefaultInstitutionCode(
        institutionsThatCanBeSeen,
        activeInstitutionCodes,
        institutionsThatCanBeSeen[0].institutionCode
      );
    }

    this.setSelectedInboxForInstitutionProfile();
    return mailOwnerType;
  }

  private profileActiveInstitutionCodes(
    activeInstitutionCodes: string[],
    children: Array<{ id: number; institutionCode: string } & any>,
    activeChildIds: number[]
  ) {
    if (this.profileRole === portalRoles.GUARDIAN) {
      const activeChildren = children.filter(child => activeChildIds.includes(child.id));
      return [...new Set(activeChildren.map(child => child.institutionCode))];
    }
    return activeInstitutionCodes;
  }

  /**
   * checks if message is sent from active institution profile (if selected inbox profile equals institution profile)
   * It returns selected mailOwnerType
   * @param  {string[]} activeInstitutionCodes
   * @param  {Array<{ id: number, institutionCode: string } & any>} children
   * @param  {number[]} activeChildIds
   * @return {boolean}  true - if message sent from active instituion profile
   */
  public isSendingFromActiveInstitution(
    activeInstitutionCodes: string[],
    children: Array<{ id: number; institutionCode: string } & any>,
    activeChildIds: number[]
  ): boolean {
    return (
      this.selectedInbox.mailBoxOwnerType !== messageOwnerTypes.INSTITUTION_PROFILE ||
      this.profileActiveInstitutionCodes(activeInstitutionCodes, children, activeChildIds).includes(
        this.selectedInbox.value
      )
    );
  }

  /**
   * sets message recipients to given prefilled new email contact.
   * It looks like recipient address is only here, so maybe it is a mistake and we should only have aulaEmail?
   * @param  {EmailContactModel} prefilledNewEmailContact
   */
  public setRecipientFromPrefilledNewEmailContact(prefilledNewEmailContact: EmailContactModel): void {
    this._messageRecipients = [
      new MessageRecipient({
        label: prefilledNewEmailContact.name,
        name: prefilledNewEmailContact.name,
        aulaEmail: prefilledNewEmailContact.aulaEmail,
        address: prefilledNewEmailContact.aulaEmail,
        id: Number(prefilledNewEmailContact.id),
        value: prefilledNewEmailContact.id,
      }),
    ];
  }

  /**
   * prepares attachments to be send, calls vue prepareAttachement filter on attachements and sets ownerInstitutionProfileId and institutionCode
   * @param  {InstitutionModel[]} institutionsThatCanBeSeen
   * @return {AttachmentModel[] & {ownerInstitutionProfileId: string; institutionCode: string} prepared list of message attachements}
   */
  public prepareAttachments(
    institutionsThatCanBeSeen: InstitutionModel[]
  ): AttachmentModel[] & { ownerInstitutionProfileId: string; institutionCode: string } {
    const messageAttachments = Vue.filter('prepareAttachments')(this._attachments);

    messageAttachments.ownerInstitutionProfileId =
      institutionsThatCanBeSeen.find(inst => inst.institutionCode === this._institutionCode)?.institutionProfileId ||
      null;

    messageAttachments.institutionCode = this._institutionCode;
    return messageAttachments;
  }

  /**
   * gets draft thread Id beased on slected draft id and oldDraftThreadId
   * @param  {string} selectedDraftId
   * @param  {string} oldDraftThreadId
   * @return calculated draft thread id
   */
  private static getDraftThreadId(selectedDraftId: string, oldDraftThreadId: string): number {
    let draftThreadId = null;
    if (oldDraftThreadId !== null && oldDraftThreadId !== '') {
      draftThreadId = oldDraftThreadId;
    }
    if (selectedDraftId !== null && selectedDraftId !== '') {
      draftThreadId = selectedDraftId;
    }
    if (!draftThreadId && localStorage.getItem('tempDraftThread') != '') {
      draftThreadId = localStorage.getItem('tempDraftThread');
    }
    return draftThreadId;
  }

  /**
   * sets messageBccRecipients to given guardians and sets message institutionCode to guardians institutionCode
   * @param  {RecipientModel[]} guardians
   */
  public setBccGuardianRecipients(guardians: RecipientModel[]): void {
    this.clearBccMessageRecipients();
    for (const guardian of guardians) {
      this._messageBccRecipients.push(
        new MessageRecipient({
          label: Vue.filter('displayProfileNameWithMetadata')(guardian),
          name: guardian.name,
          mailBoxOwnerType: messageOwnerTypes.INSTITUTION_PROFILE,
          id: guardian.id,
          portalRole: guardian.role,
          profileId: guardian.profileId,
          value: String(guardian.id),
        })
      );
    }
    const guardianBelongingToInstitution = guardians.find(guardian => guardian.institutionCode !== null);
    if (guardianBelongingToInstitution) {
      this.institutionCode = guardianBelongingToInstitution.institutionCode;
    }
  }

  /**
   * after profile change assigns new message profile, resets message subject and text
   * @param  {portalRoles} profileRole
   */
  public afterProfileChange(profileRole: portalRoles) {
    this.messageSubject = '';
    this.messageText = '';
    this.profileRole = profileRole;
  }

  /**
   * sets message institutionCode to primary institution from given  institutionsThatCanBeSeen
   * @param  {InstitutionModel[]} institutionsThatCanBeSeen
   */
  public setInstitutionCodeByPrimaryInstitution(institutionsThatCanBeSeen: InstitutionModel[]): void {
    const primaryInstitution = institutionsThatCanBeSeen.find(inst => inst.isPrimary);
    if (primaryInstitution) {
      this.institutionCode = primaryInstitution.institutionCode;
    }
  }

  /**
   * sets message selectedInbox to OTP inbox and assigns to it given value
   * @param  {number} value
   */
  public setSelectedInboxToOtp(value: number): void {
    this.selectedInbox = {
      mailBoxOwnerType: messageOwnerTypes.OTP_INBOX,
      value,
    };
  }
}
