<!-- eslint-disable vue/no-mutating-props -->
<template>
  <article
    class="message mt-2 message-type-message"
    :class="[!messageForwarded && senderIsSelf ? 'message-from-me' : 'message-from-person']"
  >
    <div class="message-container">
      <template v-if="isMobile">
        <div class="avatar-container">
          <ContactAvatar
            v-if="(messageForwarded || !senderIsSelf) && !messagesSelectable"
            :profile="senderProfile"
            class="float-right"
            :disabled="!canViewSenderProfile"
          />
          <b-form-checkbox
            v-if="messagesSelectable && !senderIsSelf"
            v-model="message.selected"
            class="custom-left message-select-checkbox message-select-checkbox-left checkmark-left"
          />
        </div>
      </template>
      <template v-else>
        <div class="avatar-container">
          <ContactAvatar
            v-if="messageForwarded || !senderIsSelf"
            :profile="senderProfile"
            :disabled="!canViewSenderProfile"
            class="float-right"
          />
        </div>
      </template>
      <div class="message-bubble" :class="[senderIsSelf ? (messagesSelectable ? 'offset-1' : '') : '', '']">
        <div class="message">
          <div class="message-meta d-flex justify-content-between">
            <div>
              <span :aria-label="getSenderAriaLabel()" class="sr-only" />
              <span v-if="message.messageType === messageTypes.AUTO_REPLY" class="autoreply-label" aria-hidden="true">{{
                'MESSAGE_AUTOREPLY_LABEL' | fromTextKey
              }}</span>
              <span aria-hidden="true">{{ message.sender | displayProfileNameWithMetadata }}</span>
            </div>
            <MessageFunctionalities v-if="canModifyMessage" :show-delete="canDeleteMessage" :message="message" />
          </div>
          <div class="message-body">
            <div class="message-content" v-html="message.text.html" />
            <div class="text-right">
              <span v-if="message.messageType === messageTypes.MESSAGE_EDITED" class="message-sent-time">{{
                'MESSAGE_EDITED' | fromTextKey
              }}</span>
              <post-time class="message-sent-time" :time="message.sendDateTime" variation="long" />
            </div>
          </div>
          <b-row class="message-meta">
            <b-col v-if="!messageForwarded" cols="6">
              <template
                v-if="
                  messageRecipients.length === 2 &&
                  seenByCount > 0 &&
                  profile.role != null &&
                  profile.role === portalRoles.EMPLOYEE
                "
              >
                {{ 'MESSAGE_SEEN' | fromTextKey }}
              </template>
              <template
                v-else-if="
                  messageRecipients.length > 2 && profile.role != null && profile.role === portalRoles.EMPLOYEE
                "
              >
                <span>
                  <span v-if="messageReceivers.length <= seenByCount">
                    {{ 'MESSAGE_SEEN_BY_ALL' | fromTextKey }}
                  </span>
                  <span v-else>
                    {{
                      'MESSAGE_SEEN_BY'
                        | fromTextKey({
                          seenByCount: seenByCount,
                          messageReceivers: messageReceivers.length,
                        })
                    }}
                  </span>
                  <template v-if="seenByCount > 0">
                    <i
                      v-if="!showListOfSeenBy"
                      class="icon-Aula_down-arrow p-1"
                      tabindex="0"
                      role="button"
                      :aria-label="'ARIA_LABEL_OPEN' | fromTextKey"
                      @click="showListOfSeenBy = true"
                      @keydown.enter="showListOfSeenBy = true"
                    />
                    <i
                      v-else
                      class="icon-Aula_up-arrow p-1"
                      tabindex="0"
                      role="button"
                      :aria-label="'ARIA_LABEL_CLOSE' | fromTextKey"
                      @click="showListOfSeenBy = false"
                      @keydown.enter="showListOfSeenBy = false"
                    />
                  </template>
                </span>
                <div
                  v-for="(receiver, i) in currentMessageListOfSeenBy"
                  v-show="showListOfSeenBy === true"
                  :key="i"
                  class="readers"
                >
                  {{ receiver | displayProfileNameWithMetadata }}
                </div>
              </template>
            </b-col>
            <b-col
              v-if="canReplyDirectlyToSender"
              cols="6"
              class="text-right"
              :class="{ 'offset-6': messageForwarded }"
            >
              <b-btn variant="link" class="forward-message" @click="forwardMessage(message)">
                <i class="icon-Aula_redirect" />
                <span> {{ replyDirectlyLink }}</span>
              </b-btn>
            </b-col>
          </b-row>
          <attachment-thumbnails
            :attachments="message.attachments"
            :parent="parentTypes.MESSAGES"
            @attachmentClicked="attachmentClicked"
          />
          <attachment-list :attachments="message.attachments" @openDocumentDrawer="openDocumentDrawer" />
        </div>
      </div>
      <template v-if="isMobile">
        <div v-if="senderIsSelf" class="avatar-container">
          <ContactAvatar
            v-if="!messageForwarded && senderIsSelf && !messagesSelectable"
            :profile="senderProfile"
            :disabled="!canViewSenderProfile"
          />
          <b-form-checkbox
            v-if="messagesSelectable && senderIsSelf"
            v-model="message.selected"
            class="custom-right message-select-checkbox message-select-checkbox-right checkmark-right"
            :value="true"
          />
        </div>
      </template>
      <template v-else>
        <div v-if="senderIsSelf" class="avatar-container">
          <ContactAvatar
            v-if="!messageForwarded && senderIsSelf"
            :profile="senderProfile"
            :disabled="!canViewSenderProfile"
          />
        </div>
      </template>
      <div v-if="messagesSelectable && !isMobile" class="message-in-middle">
        <b-form-checkbox
          v-model="message.selected"
          class="message-select-checkbox custom-right-browser"
          :value="true"
        />
      </div>
    </div>
    <aula-modal
      ref="blockedCommunicationToProfile"
      header-text="MESSAGE_WARNING_HEADER"
      :show-cancel="false"
      @okClicked="$refs.blockedCommunicationToProfile.hide()"
    >
      {{ 'WARNING_THE_COMMUNICATION_CHANNEL_IS_BLOCKED' | fromTextKey }}
    </aula-modal>
    <aula-modal
      ref="warningModalDeletion"
      header-text="MESSAGE_WARNING_HEADER"
      :is-loading="isLoading"
      ok-text="YES"
      @cancelClicked="$refs.warningModalDeletion.hide()"
      @okClicked="onConfirmDeletionClicked"
    >
      {{ 'MESSAGE_DELETE_MESSAGE_WARNING_1' | fromTextKey }}<br />
      {{ 'MESSAGE_DELETE_MESSAGE_WARNING_2' | fromTextKey }}<br />
      {{ 'MESSAGE_DELETE_MESSAGE_WARNING_3' | fromTextKey }}
    </aula-modal>
  </article>
</template>

<script>
import { types } from '../../store/types/types';
import { mapGetters, mapActions, mapMutations } from 'vuex';
import { permissionEnum } from '../../../shared/enums/permissionEnum.ts';
import { messageOwnerTypes } from '../../../shared/enums/messageOwnerTypes';
import { mediaTypes } from '../../../shared/enums/mediaTypes';
import { portalRoles } from '../../../shared/enums/portalRoles';
import AttachmentList from '../../../shared/components/AttachmentList';
import AttachmentThumbnails from '../../../shared/components/AttachmentThumbnails';
import Vue from 'vue';
import { messageTypes } from '../../../shared/enums/messageTypes';
import { parentTypes } from '../../../shared/enums/parentTypes';
import MessageFunctionalities from './MessageFunctionalities';
import { messageProviderKeyEnum } from '../../../shared/enums/messageProviderKeyEnum';
import { isAfter } from '../../../shared/utils/dateUtil';
import ContactAvatar from '../contacts/ContactAvatar.vue';
import { MessageFormActionEnum } from './enums/messageFormActionEnum';
import { hasBlockedCommunicationChannelForMessageSubscription } from '../../../shared/utils/blockedCommunicationUtil';

export default {
  components: {
    ContactAvatar,
    MessageFunctionalities,
    AttachmentList,
    AttachmentThumbnails,
  },
  provide() {
    return {
      [messageProviderKeyEnum.ON_DELETE_MESSAGE]: () => this.$refs.warningModalDeletion.show(),
    };
  },
  inject: {
    getIsThreadCreator: messageProviderKeyEnum.IS_THREAD_CREATOR,
    getIsReminderThread: messageProviderKeyEnum.GET_IS_REMINDER_THREAD,
    setMessageFormData: messageProviderKeyEnum.SET_MESSAGE_FORM_DATA,
    setSelectedMessage: messageProviderKeyEnum.SET_SELECTED_MESSAGE,
  },
  props: {
    message: {
      type: Object,
      default: null,
    },
    shownSubscription: {
      type: Object,
      default: null,
    },
  },
  data: function () {
    return {
      parentTypes: parentTypes,
      showListOfSeenBy: false,
      mediaTypes: mediaTypes,
      portalRoles: portalRoles,
      messageOwnerTypes: messageOwnerTypes,
      isLoading: false,
      messageTypes,
    };
  },
  computed: {
    ...mapGetters({
      profile: types.GET_CURRENT_PROFILE,
      institutions: types.GET_INSTITUTIONS,
      isMobile: types.GET_IS_MOBILE,
      messagesSelectable: types.MESSAGES_GET_IN_SELECT_MODE,
      hasPermission: types.HAS_PERMISSION,
    }),
    commonInboxId() {
      return this.shownSubscription.mailBoxOwner.mailBoxOwnerType === messageOwnerTypes.COMMON_INBOX
        ? this.shownSubscription.mailBoxOwner.id
        : null;
    },
    otpInboxId() {
      return this.shownSubscription.mailBoxOwner.mailBoxOwnerType === messageOwnerTypes.OTP_INBOX
        ? this.shownSubscription.mailBoxOwner.id
        : null;
    },
    canViewSenderProfile() {
      const isSenderTypeInstitutionProfile =
        this.senderProfile.mailBoxOwner?.mailBoxOwnerType === messageOwnerTypes.INSTITUTION_PROFILE;
      const isInSameInstitutionWithSender = this.institutions.some(
        institution => institution.institutionCode === this.senderProfile.institutionCode
      );
      return isSenderTypeInstitutionProfile && isInSameInstitutionWithSender;
    },
    currentMessageListOfSeenBy() {
      const currentMessageListOfSeenBy = this.messageReceivers.filter(
        recipient =>
          this.message?.sender != null &&
          (recipient.mailBoxOwner.id !== this.shownSubscription.mailBoxOwner.id ||
            recipient.mailBoxOwner.mailBoxOwnerType !== messageOwnerTypes.INSTITUTION_PROFILE) &&
          isAfter(recipient.lastReadTimestamp, this.message.sendDateTime)
      );

      currentMessageListOfSeenBy.sort((recipient1, recipient2) => {
        if (recipient1.fullName < recipient2.fullName) {
          return -1;
        }
        if (recipient1.fullName > recipient2.fullName) {
          return 1;
        }
        return 0;
      });

      if (
        this.message.sender.mailBoxOwner.id !== this.shownSubscription.mailBoxOwner.id &&
        this.shownSubscription.mailBoxOwner.mailBoxOwnerType === messageOwnerTypes.INSTITUTION_PROFILE
      ) {
        currentMessageListOfSeenBy.unshift({
          mailBoxId: this.shownSubscription.mailBoxOwner.id,
          fullName: Vue.filter('fromTextKey')('MESSAGE_SELF_READ_MESSAGE'),
        });
      }

      return currentMessageListOfSeenBy;
    },
    seenByCount() {
      return this.currentMessageListOfSeenBy.length;
    },
    isThreadCreator() {
      return this.getIsThreadCreator();
    },
    messageReceivers() {
      return this.messageRecipients.filter(
        recipient => recipient.mailBoxOwner.id !== this.message.sender.mailBoxOwner.id
      );
    },
    messageRecipients() {
      return this.shownSubscription.recipients.filter(
        recipient => recipient.leaveTime == null || isAfter(recipient.leaveTime, this.message.sendDateTime)
      );
    },
    canDeleteMessage() {
      return !this.isReminderThread;
    },
    canModifyMessage() {
      const isSender =
        [messageTypes.MESSAGE, messageTypes.MESSAGE_EDITED].includes(this.message.messageType) && this.senderIsSelf;
      const isSenderOfForwardedMessage = this.message.messageType === messageTypes.FORWARD && this.isThreadCreator;
      return (isSender || isSenderOfForwardedMessage) && this.isThreadParticipant;
    },
    replyDirectlyLink() {
      return (
        Vue.filter('fromTextKey')('MESSAGE_REPLY_TO_RECIPIENT') +
        ' ' +
        this.message.sender.answerDirectlyName +
        ' ' +
        Vue.filter('fromTextKey')('MESSAGE_REPLY_DIRECTLY')
      );
    },
    isReminderThread() {
      return this.getIsReminderThread();
    },
    canReplyDirectlyToSender() {
      return (
        !this.isInactiveMessageSender &&
        this.hasPermissionToWriteMessage &&
        !this.senderIsSelf &&
        this.messageRecipients.length > 2 &&
        !this.isSenderDeactivated &&
        !this.isThreadArchived &&
        this.message.canReplyToMessage &&
        !this.isReminderThread &&
        this.message.messageType !== messageTypes.FORWARD
      );
    },
    isInactiveMessageSender() {
      let isDeactivated = false;
      switch (this.message.sender.mailBoxOwner.mailBoxOwnerType) {
        case messageOwnerTypes.INSTITUTION_PROFILE:
          isDeactivated = this.message.sender.mailBoxOwner.isDeactivated;
          break;
        case messageOwnerTypes.OTP_INBOX:
          isDeactivated = this.message.sender.mailBoxOwner.isDeactivated;
          break;
        case messageOwnerTypes.COMMON_INBOX:
          isDeactivated = this.message.sender.mailBoxOwner.isDeleted;
          break;
      }
      return isDeactivated;
    },
    hasPermissionToWriteMessage() {
      return this.hasPermission(permissionEnum.WRITE_MESSAGE);
    },
    messageForwarded() {
      return this.message.messageType === messageTypes.FORWARD;
    },
    senderIsSelf() {
      if (this.shownSubscription != null) {
        if (this.message.sender.mailBoxOwner.mailBoxOwnerType == messageOwnerTypes.COMMON_INBOX) {
          return this.shownSubscription.mailBoxOwner.id == this.message.sender.mailBoxOwner.id;
        }
        if (this.shownSubscription.mailBoxOwner.mailBoxOwnerType == messageOwnerTypes.OTP_INBOX) {
          return this.shownSubscription.mailBoxOwner.id == this.message.sender.mailBoxOwner.id;
        } else if (this.shownSubscription.mailBoxOwner.mailBoxOwnerType != messageOwnerTypes.COMMON_INBOX) {
          if (this.institutions) {
            return this.institutions.find(item => item.institutionProfileId == this.message.sender.mailBoxOwner.id);
          } else {
            return this.message.sender.mailBoxOwner.id == this.profile.id;
          }
        }
      }
      return false;
    },
    isSenderDeactivated() {
      if (this.message.sender.mailBoxOwner.mailBoxOwnerType == messageOwnerTypes.INSTITUTION_PROFILE) {
        return this.message.sender.mailBoxOwner.isDeactivated;
      }
      return false;
    },
    isThreadArchived() {
      return this.shownSubscription.isArchived;
    },
    isThreadParticipant() {
      return this.shownSubscription.leaveTime == null;
    },
    senderProfile() {
      return {
        id: this.message.sender.mailBoxOwner.id,
        ...this.message.sender,
      };
    },
  },
  methods: {
    ...mapActions({
      resolveBlockedUsers: types.ACTION_GET_BLOCKED_COMMUNICATION,
      deleteMessage: types.DELETE_MESSAGE,
    }),
    ...mapMutations({
      updateThreadLatestMessage: types.MUTATE_THREAD_LATEST_MESSAGE,
    }),
    async onConfirmDeletionClicked() {
      const deletedMessageText = Vue.filter('fromTextKey')('MESSAGE_IS_DELETED');
      this.isLoading = true;
      await this.deleteMessage({ threadId: this.shownSubscription.id, messageId: this.message.id });
      this.updateThreadLatestMessage({
        latestMessage: deletedMessageText,
        threadId: this.shownSubscription.id,
        messageId: this.message.id,
      });
      this.isLoading = false;
    },
    openDocumentDrawer(data) {
      this.$emit('openDocumentDrawer', data);
    },
    attachmentClicked(mediaId) {
      this.$router.push('/beskeder/' + this.shownSubscription.id + '/medie/' + mediaId);
    },
    getSenderAriaLabel() {
      return Vue.filter('fromTextKey')('ARIA_LABEL_MESSAGE_FROM') + this.message.sender.fullName;
    },
    async hasBlockedCommunicationChannels(recipient) {
      return hasBlockedCommunicationChannelForMessageSubscription(
        this.resolveBlockedUsers,
        this.shownSubscription,
        recipient
      );
    },
    async forwardMessage(message) {
      const recipient = message.sender;
      recipient.id = recipient.mailBoxOwner.id;
      recipient.role = recipient.mailBoxOwner.mailBoxOwnerType;
      const canForwardMessage = !(await this.hasBlockedCommunicationChannels(recipient));
      if (canForwardMessage) {
        this.setSelectedMessage(this.message);
        this.setMessageFormData({
          threadId: this.shownSubscription.id,
          commonInboxId: this.commonInboxId,
          otpInboxId: this.otpInboxId,
          action: MessageFormActionEnum.ANSWER_DIRECTLY,
        });
        await this.$router.push({
          name: 'newMessage',
        });
      } else {
        this.$refs.blockedCommunicationToProfile.show();
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import '../../../shared/assets/scss/core/_variables.scss';
@import '../../../shared/assets/scss/core/_breakpoints.scss';

.message-type-message {
  .message-container {
    display: flex;
    gap: 16px;
  }
  .avatar-container {
    margin-top: 20px;
  }
}

.message-bubble {
  flex-grow: 1;
}
.message-content {
  overflow: hidden;
}
.message-sent-time {
  font-size: 0.75rem;
}
.checkbox {
  align-self: flex-start;
}

.custom-right {
  top: 15px;
  margin-right: auto;
  margin-left: 0;
}

.custom-right-browser {
  left: 0;
  margin-right: 0px;
  margin-left: 0px;
  position: absolute !important;
  float: left !important;
}

.message-in-middle {
  position: absolute;
  right: -12px;
}

.custom-left {
  top: 15px;
  margin-left: auto;
  margin-right: 0;
}

.message-select-checkbox {
  width: 35px;
  height: 35px;
}

.checkbox-column {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.readers {
  margin-bottom: 3px;
}

.forward-message {
  font-size: 12px;
  cursor: pointer;
  .theme-employee & {
    color: $color-primary-dark-employee;
  }
  .theme-guardian & {
    color: $color-primary-dark-guardian;
  }
  .theme-child & {
    color: $color-primary-dark-child;
  }
  .theme-admin & {
    color: $color-primary-dark-admin;
  }
  .icon-Aula_redirect {
    font-size: 13px;
    margin-right: 0px;
    border-radius: 5px;
    padding: 2px 3px;
    .theme-employee & {
      color: $color-primary-dark-employee;
    }
    .theme-guardian & {
      color: $color-primary-dark-guardian;
    }
    .theme-child & {
      color: $color-primary-dark-child;
    }
    .theme-admin & {
      color: $color-primary-dark-admin;
    }
  }
}
</style>
